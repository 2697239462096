const { ERROR_IDENTIFIER } = require('../../constants/app');

class MelidataErrorPayload {
  constructor(props, history) {
    const { flowId, showRetry, error_code, preferenceId, collectorId, payerId } = props;
    const historyState = history.location?.state;

    this.dataError = {
      checkout_flow_id: flowId || historyState?.flow_id,
      preference_id: preferenceId || historyState?.preference_id,
      payer_id: payerId || historyState?.payer_id || '',
      collector_id: collectorId || historyState?.collector_id,
      errors: {
        cause: historyState?.groupError || ERROR_IDENTIFIER.UNEXPECTED_ERROR,
        code: error_code || historyState?.error_code || ERROR_IDENTIFIER.NOT_APPLICABLE,
        detail: historyState?.origin_error || ERROR_IDENTIFIER.UNEXPECTED_ERROR,
        is_recoverable: !!showRetry || !!historyState?.showRetry,
        service: ERROR_IDENTIFIER.NOT_APPLICABLE,
        service_error_detail: ERROR_IDENTIFIER.NOT_APPLICABLE,
      },
    };
  }

  /**
   * Get a object of all tags
   */
  getPayload() {
    return this.dataError;
  }
}

module.exports = MelidataErrorPayload;
