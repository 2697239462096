const React = require('react');

const Error404Illustration = () => (
  <svg aria-hidden="true" width="320" height="128" viewBox="0 0 320 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1811_84641)">
      <rect width="320" height="128" fill="none"></rect>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M192.162 5.05468C179.441 3.28901 164.364 6.08194 154.657 14.8924C143.999 24.5625 135.494 30.3302 123.748 33.3933C112.533 36.3209 91.4712 36.7486 80.2281 50.5405C66.2277 67.7131 75.4886 89.8215 95.4386 104.355C117.732 120.598 160.103 136.09 198.294 121.577C235.635 107.386 244.506 73.4254 240.39 53.0161C234.684 24.717 209.571 7.4684 192.162 5.05468"
        fill="black"
        fill-opacity="0.04"
      ></path>
      <rect x="99.2554" y="15.5566" width="127.241" height="97.2863" rx="5" fill="white"></rect>
      <path
        d="M100.755 32.5708H224.997V107.843C224.997 109.776 223.43 111.343 221.497 111.343H104.255C102.322 111.343 100.755 109.776 100.755 107.843V32.5708Z"
        fill="#EEEEEE"
        stroke="white"
        stroke-width="3"
      ></path>
      <path
        d="M99.2554 89.5642V20.5566C99.2554 17.7952 101.494 15.5566 104.255 15.5566H221.497C224.258 15.5566 226.497 17.7952 226.497 20.5566V42.8142M99.2554 93.5642V94.2485V98.2485V107.843C99.2554 110.604 101.494 112.843 104.255 112.843H221.497C224.258 112.843 226.497 110.604 226.497 107.843V60.2485V56.2485V53.6892M226.497 46.9064V49.2485V49.8767"
        stroke="#333333"
        stroke-width="1.5"
      ></path>
      <path d="M99.2554 31.0708H226.497" stroke="#333333" stroke-width="1.5"></path>
      <ellipse cx="106.532" cy="23.4278" rx="1.37345" ry="1.37236" fill="#333333"></ellipse>
      <ellipse cx="113.857" cy="23.4278" rx="1.37345" ry="1.37236" fill="#333333"></ellipse>
      <ellipse cx="121.182" cy="23.4278" rx="1.37345" ry="1.37236" fill="#333333"></ellipse>
      <path
        d="M165.882 58.1767L178.608 77.7748C180.092 80.0613 178.451 83.0834 175.725 83.0834H150.273C147.547 83.0834 145.906 80.0613 147.391 77.7748L160.117 58.1767C161.472 56.0898 164.526 56.0898 165.882 58.1767Z"
        fill="white"
        stroke="white"
        stroke-width="3"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.859 57.3598C160.805 54.3621 165.193 54.3621 167.14 57.3598L179.866 76.9579C181.998 80.2423 179.641 84.5834 175.725 84.5834H150.273C146.357 84.5834 144 80.2423 146.133 76.9579L158.859 57.3598Z"
        stroke="#333333"
        stroke-width="1.5"
      ></path>
      <path d="M164.599 64.583H161.399L161.799 73.383H164.199L164.599 64.583Z" fill="#333333"></path>
      <path
        d="M162.999 74.983C163.883 74.983 164.599 75.6994 164.599 76.583C164.599 77.4667 163.883 78.183 162.999 78.183C162.116 78.183 161.399 77.4667 161.399 76.583C161.399 75.6994 162.116 74.983 162.999 74.983Z"
        fill="#333333"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1811_84641">
        <rect width="320" height="128" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

module.exports = Error404Illustration;
