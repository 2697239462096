const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');
const Optimus = require('../../containers/Optimus');
const Page = require('../../components/Page');
const Button = require('../../containers/Button/ButtonWithStore');
const stepActions = require('../../spa/actions/step');
const sizeActions = require('../../spa/actions/size');
const { getQueryParams } = require('../../utils/Dom');
const { STEP_RETRY, CHANGE_IFRAME_SIZE } = require('../../spa/actions/types');
const Resizer = require('../../components/Resizer');
const { setCookie, getCookie } = require('../../utils/CookieUtils');
const { trackMelidata } = require('../../utils/Tracker');
const {MelidataTrack} = require('nordic/melidata/melidata-track');
const {
  COOKIES: { RECOVERY_ERROR_COUNTER },
  ERROR_IDENTIFIER,
  TRACK_EVENT: { ACTION, CATEGORY },
  ERROR_TYPES: { TECHNICAL_ERROR },
  TRACKING_PATH,
} = require('../../../constants/app');
const { isErrorCode } = require('../../utils/HiddenComponents');
const MelidataErrorPayload = require('../../utils/MelidataErrorPayload');
const ExceptionError = require('../../components/ExceptionError');

function setErrorCodeForTechnicalError(components, errorCode) {
  if (!Array.isArray(components) || components?.length === 0) {
    return false;
  }

  const isTechnicalError = components[0]?.type === TECHNICAL_ERROR;
  if (isTechnicalError && errorCode) {
    components[0].errorCode = errorCode;
  }
  return true;
}

class Exception extends React.Component {
  constructor(props) {
    super(props);
    const { i18n, history } = props;
    this.state = {
      retryCounter: 0,
      dataError: new MelidataErrorPayload(props, history).getPayload(),
    }
    this.handleRetry = this.handleRetry.bind(this);
    this.redirectToStepRetry = this.redirectToStepRetry.bind(this);
    this.getRecoveryCookie = this.getRecoveryCookie.bind(this);
    this.translations = translate(i18n);
  }

  componentDidMount() {
    const size = 1000;
    // eslint-disable-next-line no-unused-expressions
    this.props.sizeActions[CHANGE_IFRAME_SIZE](size);
    this.setState({ retryCounter: this.getRecoveryCookie() });
    if (this.state.dataError && this.props.history.location.state?.error_code) {
      trackMelidata(
        {
          path: TRACKING_PATH.MELIDATA_CHECKOUT_OFF_ERROR,
          payload: this.state.dataError,
        },
        this.props,
      );
    }
  }

  handleRetry(flow, errorCode) {
    let counter = this.state.retryCounter?.flowId === flow?.id ? this.state.retryCounter?.counter : 0;

    if (counter > 2) {
      return;
    }

    counter++;
    const errorComesFrom = errorCode?.substring(0, 2);
    setCookie(RECOVERY_ERROR_COUNTER, JSON.stringify({ flowId: flow?.id, counter, errorComesFrom }), 0.125); // 3 hours
    // The FL identifier refers to errors that come from flows
    errorComesFrom === ERROR_IDENTIFIER.FLOWS ?  window.history.back() : this.redirectToStepRetry(flow);
  }

  getRecoveryCookie() {
    return getCookie(RECOVERY_ERROR_COUNTER) ? JSON.parse(getCookie(RECOVERY_ERROR_COUNTER)) : null;
  }

  redirectToStepRetry(flow) {
    this.props.stepActions[STEP_RETRY](flow?.id, flow?.type, getQueryParams(), this.props.history);
  }

  render() {
    const {
      captcha,
      stepTitle,
      siteId,
      error_code,
      error_type,
      trackingPath,
      analytics,
      deviceType,
      history,
      showRetry,
      components,
      hidden_components,
      publicKey,
      urls,
    } = this.props;

    const errorCodeFlows = isErrorCode(hidden_components);
    const errorCodeFront = error_code || history.location?.state?.error_code;
    const errorCode = errorCodeFront || errorCodeFlows?.code;

    const { retryCounter } = this.state;
    const retryFromFront = showRetry || history.location?.state?.showRetry;
    const retryFromFlows = errorCodeFlows?.recoverable;
    const maximiumRetryAttempts = !(retryCounter?.flowId === this.props.flow?.id && retryCounter?.counter > 2);
    const showRetryButton = (retryFromFront || retryFromFlows) && maximiumRetryAttempts;

    const isControlledError = errorCodeFlows?.error_type !== ERROR_IDENTIFIER.UNEXPECTED_ERROR;

    setErrorCodeForTechnicalError(components, error_code);

    return (
      <>
      <Page
        title={stepTitle}
        currentStep="exception"
        urls={urls}
        trackingPath={trackingPath}
        analytics={analytics}
        deviceType={deviceType}
        trackPageToMelidata={!errorCodeFront}
      >
      <MelidataTrack path={TRACKING_PATH.MELIDATA_CHECKOUT_OFF_ERROR} event_data={this.state.dataError} />

        {isControlledError && errorCodeFlows ? (
          <Optimus
            components={components}
            history={history}
            siteId={siteId}
            urls={urls}
            publicKey={publicKey}
            deviceType={deviceType}
            captcha={captcha}
            hiddenComponents={hidden_components}
          />
        ) : (
          <>
            <ExceptionError errorCode={errorCode} errorType={error_type}/>

            <Resizer />
            {showRetryButton && (
              <Button
                trackEvent={{
                  action: ACTION.CLICK_RETRY_EXCEPTION_BUTTON,
                  category: CATEGORY.VISIBLE_COMPONENT,
                  label: 'RETRY',
                  type: 'CLICK',
                }}
                id="back_btn_retry"
                text={this.translations.RETRY}
                kind="link"
                onClick={() => this.handleRetry(this.props.flow, errorCode)}
              />
            )}
          </>
        )}
      </Page>
      </>
    );
  }
}

/**
 * Prop Types
 */
Exception.propTypes = {
  flow: PropTypes.object, // eslint-disable-line
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  stepTitle: PropTypes.string,
  urls: PropTypes.shape({
    tyc: PropTypes.shape({
      link: PropTypes.string,
    }),
  }),
  pageData: PropTypes.object, // eslint-disable-line
  trackingPath: PropTypes.string,
  analytics: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  deviceType: PropTypes.string,
  stepActions: PropTypes.object, // eslint-disable-line
  showRetry: PropTypes.bool,
  history: PropTypes.object, // eslint-disable-line
  error_code: PropTypes.string,
  error_type: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.object),
  hidden_components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
    }),
  ),
  siteId: PropTypes.string,
  publicKey: PropTypes.string,
};

/**
 * Default Props
 */
Exception.defaultProps = {
  flow: null,
  stepTitle: '',
  urls: {
    tyc: {
      link: '',
    },
  },
  currentStep: '',
  pageData: {},
  trackingPath: 'failure',
  analytics: {},
  deviceType: '',
  stepActions: {},
  i18n: {
    gettext: (t) => t,
  },
  showRetry: false,
  history: {},
  error_code: '',
  error_type: '',
  components: [],
  hidden_components: [],
  siteId: '',
  publicKey: '',
};

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActions, dispatch),
  sizeActions: bindActionCreators(sizeActions, dispatch),
});
/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page?.flow,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Exception;
  module.exports.setErrorCodeForTechnicalError = setErrorCodeForTechnicalError;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = injectI18n(connect(mapStateToProps, mapDispatchToProps)(Exception));
}
